<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12">
          <label
            :class="{
              'p-error': v$.form.account.$invalid && submitted,
            }"
          >
            Perkiraan
          </label>
          <AutoComplete
            autofocus
            ref="account"
            v-model="form.account"
            :suggestions="acAccount"
            :min-length="2"
            :class="{
              'p-invalid': v$.form.account.$invalid && submitted,
            }"
            class="w-full"
            input-class="w-full"
            field="nama"
            @complete="searchAccount($event)"
          >
            <template #item="slotProps">
              <div>{{ slotProps.item.kode }} - {{ slotProps.item.nama }}</div>
            </template>
          </AutoComplete>
          <small
            v-if="
              (v$.form.account.$invalid && submitted) ||
              v$.form.account.$pending.$response
            "
            class="p-error"
            >{{ v$.form.account.required.$message }}</small
          >
        </div>
        <div class="field col-12">
          <label
            :class="{
              'p-error': v$.form.keterangan.$invalid && submitted,
            }"
          >
            Keterangan</label
          >
          <InputText
            v-model="form.keterangan"
            type="text"
            :class="{
              'p-invalid': v$.form.keterangan.$invalid && submitted,
            }"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.keterangan.$invalid && submitted) ||
              v$.form.keterangan.$pending.$response
            "
            class="p-error"
            >{{ v$.form.keterangan.required.$message }}</small
          >
        </div>
        <div class="field col-12">
          <label>No. Referensi</label>
          <InputText v-model="form.reff_no" type="text" class="w-full" />
        </div>
        <div class="field col-12">
          <label
            :class="{
              'p-error': v$.form.nilai.$invalid && submitted,
            }"
          >
            Nilai
          </label>
          <InputNumber
            v-model="form.nilai"
            :minFractionDigits="0"
            :maxFractionDigits="2"
            :class="{
              'p-invalid': v$.form.nilai.$invalid && submitted,
            }"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
          />
          <div
            v-if="
              (v$.form.nilai.$invalid && submitted) ||
              v$.form.nilai.$pending.$response
            "
            class="p-error"
          >
            <small>{{ v$.form.nilai.between.$message }}</small>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="onCloseForm"
          class="p-button-outlined mr-2"
        />
        <Button
          v-if="item.uuid"
          label="Ubah"
          icon="pi pi-check"
          type="submit"
        />
        <Button v-else label="Tambahkan" icon="pi pi-check" type="submit" />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { between, helpers, required } from '@vuelidate/validators'
import errorHandler from '@/helpers/error-handler'
import AccountService from '@/services/AccountService'
import { formatNumber } from '@/helpers'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
    saldo: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
      accountService: null,
      acAccount: [],
      form: this.item,
    }
  },
  created() {
    this.accountService = new AccountService()
  },
  methods: {
    searchAccount(e) {
      this.accountService
        .get('?filter[nama]=' + e.query)
        .then((res) => {
          this.acAccount = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Perkiraan', this)
        })
    },
    onCloseForm() {
      this.$emit('close')
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
      this.reset()
    },
    reset() {
      this.submitted = false
      this.form = {
        account: null,
        keterangan: '',
        reff_no: '',
        nilai: 0,
      }
      this.$refs.account.$el.children[0].focus()
    },
    getSaldo() {
      if (this.item.id > 0) {
        return (
          this.item.lastNilai + this.saldo + Math.abs(this.item.selisihNilai)
        )
      } else {
        if (this.item.selisihNilai) {
          return this.saldo + Math.abs(this.item.selisihNilai)
        } else {
          return this.saldo
        }
      }
    },
    formatNumber(value) {
      value = value ? value : 0
      return formatNumber(value)
    },
  },
  validations() {
    return {
      form: {
        account: {
          required: helpers.withMessage('Perkiraan harus diisi.', required),
        },
        keterangan: {
          required: helpers.withMessage('Keterangan harus diisi.', required),
        },
        nilai: {
          required: helpers.withMessage(
            'Nilai harus di antara 1 dan ' +
              formatNumber(this.getSaldo()) +
              '.',
            required
          ),
          between: helpers.withMessage(
            'Nilai harus di antara 1 dan ' +
              formatNumber(this.getSaldo()) +
              '.',
            between(1, this.getSaldo())
          ),
        },
      },
    }
  },
}
</script>
